<template>
  <b-card-body>
    <b-row>
      <b-col cols="12" md="4" class="mb-md-0 mb-2">
        <label>Tipo</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="typeFilter"
          :options="typeOptions"
          class="w-100"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:typeFilter', val)"
        />
      </b-col>
      <b-col cols="12" md="4" class="mb-md-0 mb-2">
        <label>Disponibilità</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="availabilityFilter"
          :options="availabilityOptions"
          class="w-100"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:availabilityFilter', val)"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    typeFilter: {
      type: [String, null],
      default: null,
    },
    availabilityFilter: {
      type: [String, null],
      default: null,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    availabilityOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
